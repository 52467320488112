/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import * as gifShot from "gifshot";
import { Howl, Howler } from "howler";
import Confetti from 'react-confetti';
import LoggingService from "services/logging";
//import { datadogRum } from '@datadog/browser-rum';

import timerTurning from "animations/timerTurning.js";
import timerEnd from "animations/timerEnd.js";
import potatoDraw from "animations/potatoDraw.js";
import potatoWrite from "animations/potatoWrite.js";
import tickCard from 'animations/tickCard.js';
//import chooseCards from 'animations/titles/chooseCards.js';
//import bigRevealAnim from 'animations/titles/bigReveal.js';
//import howToPlayAnim from 'animations/titles/howToPlay.js';
//import winnerIsAnim from 'animations/titles/winnerIs.js';

import Loading from "components/Loading";
import Player from "components/Player";
import Menu from "components/Menu";
import getAvatarById from "constants/avatars";

import logo from "images/scrawl-logo.png";
import logo17 from "images/scrawl-logo-17.png";
import frame from "images/frame.png";
import mutedIcon from "images/scrawl_muted.png";
import unmutedIcon from "images/scrawl_unmuted.png";
import fullscreenIcon from "images/scrawl_fullscreen.png";
import helpIcon from "images/scrawl_help.png";
import cardsImg from "images/cards-img.png";
import bannerImg from "images/banner.png";
import familyIcon from "images/Family Icon.png"
import adultsIcon from "images/Adults Icon.png"

import Tutorial from "components/Tutorial";

import playingBgMusic from "audio/playing-bg-music.mp3";
import galleryMusic from "audio/gallery-reveal-music.mp3";
import bigRevealSFX from "audio/big-reveal.mp3";
import favouriteChosenBackgroundSFX from "audio/favourite-chosen-background.mp3";
import favouriteChosenSFX from "audio/favourite-chosen-sound.mp3";
import playerReadySFX from "audio/player-ready.mp3";
import playerReady2SFX from "audio/player-ready-2.mp3";
import playerReady3SFX from "audio/player-ready-3.mp3";
import playerReady4SFX from "audio/player-ready-4.mp3";
import playerReady5SFX from "audio/player-ready-5.mp3";
import playerReady6SFX from "audio/player-ready-6.mp3";
import revealOneSFX from "audio/reveal-one.mp3";
import roundStartDrawingSFX from "audio/round-start-drawing.mp3";
import roundStartWritingSFX from "audio/round-start-writing.mp3";
import winnerSFX from "audio/winner.mp3";
import joinedSFX from "audio/player-joined.mp3";
import hoverOrClickSFX from "audio/hover-click.mp3";

import ErrorModal from 'components/Utility/ErrorModal';

import * as Sentry from "@sentry/react";
import constants from "constants/constants";

import "animate.css";
import styles from 'components/HostStyles.module.scss';


const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

const readySFX = [
    playerReady5SFX,
    playerReady3SFX,
    playerReadySFX,
    playerReady2SFX,
    playerReady6SFX,
    playerReady4SFX,
]

const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    ChoosingCards: "choosing_cards",
    Playing: "playing",
    Revealing: "revealing",
    Awarding: "awarding",
    Idle: "idle",
    Results: "results",
    EndGame: "end_game",
};

const gameId = "scrawl";

const PlayerColours = [
    "#35A8E0",
    "#37B4AA",
    "#93C01F",
    "#FFEC00",
    "#FAB900",
    "#E74E0F",
    "#E40076",
    "#633587",
];



export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true, //debug
            muted: false,
            menuOpen: false,
            gameBegun: false,
            reconnectionToken: "",
            contentFilter: 0,
            availableAddons: [],
            logStreamId: "",

            isGame: false,
            //isGame: true, //debug
            showRoundNumbers: false,
            roundNumber: 0,
            totalRounds: 0,
            players: [],
            //players: [ //debug
            //    {
            //        name: "SCOTT",
            //        id: "test-id-1",
            //        avatar: 2,
            //        showPlayer: true,
            //        scrawlData: {
            //            isPlaying: true,
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-2",
            //        avatar: 2,
            //        showPlayer: true,
            //        isPlaying: true,
            //        scrawlData: {
            //            isPlaying: true,
            //            score: 0,
            //        }
            //    },
            //    {
            //        name: "WWWWWWWWWW",
            //        id: "test-id-3",
            //        avatar: 2,
            //        showPlayer: true,
            //        isPlaying: true,
            //        scrawlData: {
            //            isPlaying: true,
            //            score: 0,
            //        }
            //    }],
            playersRequired: 3,
            gameState: GameStates.Loading,
            isDrawing: false,
            isWriting: false,
            roundTitle: "",
            isRevealing: false,
            isCards: false,
            playerRevealing: {},
            storyList: [],
            ogCard: "",
            revealTitle: "",
            choosingBest: false,
            highlightBest: false,
            hideStory: false,
            popOutPotato: false,
            bestItem: null,
            bestItemPlayer: {},
            showWinners: false,
            winners: [],
            winnersText: "",
            showPlayAgain: false,
            showTimer: false,
            timer: 10,
            timerOptions: timerTurning,
            shrinkLogo: false,
            //shrinkLogo: true, //debug
            doodlesOne: [],
            doodlesTwo: [],
            doingTutorial: false,
            showTutorial: false,

            maxVoteCount: 0,
            skipTutorialCount: 0,
            allRevealed: false,
            revealCount: 0,

            animContext: "",
            showPlayers: false,
            //showPlayers: true, //debug
            dissapearBest: false,
            showStartGame: false,
            //showStartGame: true, //debug

            readySFXIndex: 0,
            readySFXArray: readySFX,

            tickedSkipTutorial: false,
            leaderboardPlayers: [],
            doConfetti: false,

            playTitle: false,
            titleText: "",

            gotLocationPing: true,
            connectionIssue: false,

            joinError: false,
            isSmartTV: false,

            showStartWarning: false,
            showContentWarning: false,
            playerConnections: {},
            gameCount: 0,
        };
        this.titleRefs = {
            "Cards": React.createRef(),
            "BigReveal": React.createRef(),
            "Tutorial": React.createRef(),
            "WinnersAre": React.createRef(),
        }
        this.titleAnimRef = React.createRef();
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);


        this.gameBgAudio = new Howl({
            src: playingBgMusic,
            loop: true,
            volume: 0.2,
        });
        this.galleryBgAudio = new Howl({
            src: galleryMusic,
            loop: true,
            volume: 0.7,
        });
    }

    async componentDidMount() {
        this.setTags();
        this.doReconnect();

        this.toggleMute(true, false);
        this.preloadAudio([
            bigRevealSFX,
            favouriteChosenBackgroundSFX,
            favouriteChosenSFX,
            revealOneSFX,
            roundStartDrawingSFX,
            roundStartWritingSFX,
            winnerSFX,
            joinedSFX,
            hoverOrClickSFX,
            ...readySFX
        ]);
        //this.playAudio(playingBgMusic, true, 0.2);
        Howler.volume(0.6);
        this.gameBgAudio.play();
        this.preloadImage(frame);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [ roomId, reconnectToken ] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    preloadImage(src) {
        const startDate = new Date();

        const img = new Image();
        img.onload = () => {
            const endDate = new Date();
            const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
            console.log(`Images loaded in ${seconds} seconds!`);
        }
        img.src = src; // by setting an src, you trigger browser download
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    createGIF = (images) => {
        gifShot.createGIF({
            images,
            interval: .8,
            gifWidth: 1200,
            gifHeight: 800,
        }, function (obj) {
            if (!obj.error) {
                var image = obj.image;

                var link = document.createElement("a");
                link.download = "gifTest.gif";
                link.href = image;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                //delete link;
            }
        })
    };

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    preloadAudio(audioFiles) {
        for (let i = 0; i < audioFiles.length; i++) {
            let audio = new Howl({
                src: [audioFiles[i]],
                preload: true
            });
        }
    }

    playAudio(audioFile, loop = false, volume = 1) {
        //let audio = new Audio(audioFile);
        //audio.play();
        let audio = new Howl({
            src: [audioFile],
            loop: loop,
            volume: volume,
        });
        audio.play();
    }

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen === true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = this.state.muted === true ? false : true;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id !== id), }
        });
    }

    addPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    populateLeftColumn() {
        let players = [];
        for (let i = 0; i < 4; i++) {
            if (this.state.players[i]) {
                players.push(<Player player={this.state.players[i]} number={i + 1} left={true} winner={false} hide={this.isWinner(this.state.players[i].id)} colour={PlayerColours[i]} />);
            } else {
                players.push(<Player isEmpty={true} left={true} />);
            }
        }
        return players;
    }

    populateRightColumn() {
        let players = [];
        for (let i = 4; i < 8; i++) {
            if (this.state.players[i]) {
                players.push(<Player player={this.state.players[i]} number={i + 1} left={false} winner={false} hide={this.isWinner(this.state.players[i].id)} colour={PlayerColours[i]} />);
            } else {
                players.push(<Player isEmpty={true} left={false} />);
            }
        }
        return players;
    }

    populatePlayers() {
        let players = [];
        for (let i = 0; i < this.state.players.length; i++) {
            if (this.state.players[i]) {
                players.push(<Player player={this.state.players[i]} number={i + 1} left={false} winner={false} /*hide={this.isWinner(this.state.players[i].id)}*/ colour={PlayerColours[i]} />);
            } else {
                players.push(<Player isEmpty={true} left={false} />);
            }
        }
        return players;
    }

    isWinner(id) {
        let match = false;
        for (let i = 0; i < this.state.winners.length; i++) {
            console.log("looking for match : " + id);
            console.log("comparing to: " + this.state.winners[i].id);
            if (this.state.winners[i].id === id) {
                console.log("found a match!");
                match = true;
            }
        }
        return match;
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    getReadySFX() {
        let sfx = "";
        if (this.state.readySFXIndex >= this.state.readySFXArray.length) {
            this.setState(prevState => {
                return {
                    readySFXArray: this.shuffleArray(prevState.readySFXArray),
                    readySFXIndex: 0
                }
            }, () => {
                sfx = this.state.readySFXArray[this.state.readySFXIndex];
            });

        } else {
            sfx = this.state.readySFXArray[this.state.readySFXIndex];

            this.setState(prevState => {
                return {
                    readySFXIndex: prevState.readySFXIndex + 1,
                }
            });

        }

        return sfx;
    }

    setPlayerReady(id) {
        this.playAudio(this.getReadySFX(), false, 0.2);
        let players = [...this.state.players];

        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.scrawlData.ready = true;

        players[pos] = player;

        this.setState({ players });
    }

    startRevealing(player) {
        let revealCount = this.state.revealCount;
        revealCount++;
        this.unreadyAll();
        let timeoutTime = 0;
        this.toggleTimer(false);
        if (revealCount <= 1) {


            this.playAudio(bigRevealSFX, false, 0.6);
            this.doTitleAnim("BigReveal");
        }
        else {
            this.playAudio(roundStartWritingSFX, false, 0.6);
            timeoutTime = 1000;
            this.setState({ dissapearBest: true, });
        }
        this.setState({ playerRevealing: player, storyList: player.scrawlData.story, ogCard: player.scrawlData.cardText, allRevealed: false, });
        setTimeout(() => {
            this.setState({ revealCount, showRoundNumbers: false, dissapearBest: false, showPlayers: false, isRevealing: true, highlightBest: false, hideStory: false, popOutPotato: false, choosingBest: false, bestItem: null, revealTitle: `Reveal each image using your device!`, });

        }, timeoutTime);
    }

    revealNext(story) {
        //console.log("story: " + JSON.stringify(story));
        this.playAudio(revealOneSFX, false, 0.25);
        for (let i = 0; i < story.length; i++) {
            if (story[i].revealed) {
                if (story[i + 1]) {
                    if (story[i + 1].revealed) story[i].hide = true;
                }
            }
        }
        this.setState({ storyList: story, });
    }

    allRevealed() {
        setTimeout(() => {
            let story = [...this.state.storyList];
            story.forEach(x => {
                x.hide = true;
            });
            this.setState({ choosingBest: true, storyList: story, /*allRevealed: true,*/ });
        }, 3000);
        //this.setState({ revealTitle: `Choose your favourite addition to award a point!`, });
    }

    chooseBest(item, player) {
        this.setState({ highlightBest: true, allRevealed: true, bestItem: item, bestItemPlayer: player, choosingBest: false, revealTitle: `The best addition was by: ${item.byName}!`, });
        this.playAudio(favouriteChosenBackgroundSFX, false, 0.35);
        this.playAudio(favouriteChosenSFX, false, 0.35);
        setTimeout(() => {
            this.setState({ popOutPotato: true, })
        }, 750);

        setTimeout(() => {
            this.setState({ storyList: [], });
        }, 2000);
        setTimeout(() => {
            this.state.room.send("finish_awarding");
        }, 4000);
    }

    noBest() {
        setTimeout(() => {
            let story = [...this.state.storyList];
            story.forEach(x => {
                x.hide = true;
            });
            this.setState({ choosingBest: true, storyList: story, allRevealed: true, });

            setTimeout(() => {
                this.setState({ hideStory: true, });
                setTimeout(() => {
                    this.setState({ storyList: [], });
                    this.state.room.send("finish_awarding");
                }, 1500);
            }, 2000);
        }, 3000);
    }


    updateScores(players) {
        let statePlayers = [...this.state.players];
        for (const [, value] of Object.entries(players)) {
            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(value.id);
            let player = { ...statePlayers[pos] };
            player.scrawlData.score = value.scrawlData.score;
            statePlayers[pos] = player;
        }
        this.setState({ revealTitle: ``, players: statePlayers, });
    }

    doGameOver(winners, players) {
        this.setState({ dissapearBest: true, });
        this.galleryBgAudio.pause();
        this.gameBgAudio.play();

        this.getLeaderboardList(players);

        setTimeout(() => {
            //this.setState({ showPlayers: true, });
            setTimeout(() => {
                let winnersText = ``;
                if (winners.length > 1) {
                    winnersText = "Winners!";
                } else {
                    winnersText = "Winner!";
                }
                this.setState({ isRevealing: false, highlightBest: false, hideStory: false, popOutPotato: false, choosingBest: false, storyList: [], bestItem: null, winners, winnersText, });
                this.playAudio(bigRevealSFX, false, 0.6);
                this.doTitleAnim("WinnersAre");
            }, 1000);
        }, 2000);
    }

    showPlayAgain() {
        let stateWinners = [...this.state.winners];
        for (let i = 0; i < stateWinners.length; i++) {
            setTimeout(() => {
                let player = { ...stateWinners[i] };
                player.showSelf = false;
                stateWinners[i] = player;
                this.setState({ winners: stateWinners });
            }, (i * 300));
        }
        setTimeout(() => {
            this.state.room.send("reached_end", {});
            this.setState({ showWinners: false, winners: [], winnersText: "", showPlayAgain: true, });
            if (constants.isBot) {
                setTimeout(() => {
                    this.playAgain(true);
                }, 5000);
            }
        }, 3000);
    }

    hidePlayAgain() {
        this.setState({ showPlayAgain: false, showPlayers: true, });
    }

    playAgain = (confirm = false) => {
        //this.hidePlayAgain();
        let allConnected = true;
        console.log("Player Connections : ", this.state.playerConnections);
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            console.log(`key : `, value);
            if (value === false) allConnected = false;
        }

        console.log("confirm : ", confirm);
        console.log("all connected : ", allConnected);

        if (!confirm && !allConnected) {
            console.log("Show Start Warning");
            this.setState({ showStartWarning: true });
        } else {
            console.log("Play Again");
            this.playAudio(hoverOrClickSFX, false, 0.6);
            this.setState({ showStartWarning: false });
            this.state.room.send("new_game", {});
            this.setState({ gameCount: this.state.gameCount + 1, });
        }
    }

    differentGame = () => {
        this.playAudio(hoverOrClickSFX, false, 0.6);
        this.hidePlayAgain();
        this.state.room.send("change_game", {});
    }

    resetRound() {
        this.setState({ isRevealing: false, highlightBest: false, hideStory: false, popOutPotato: false, });
    }

    resetGame(players) {
        this.hidePlayAgain();
        let statePlayers = [...this.state.players];
        statePlayers.forEach(x => {
            x.scrawlData = players[x.id].scrawlData;
        });
        this.setState({ showWinners: false, players: statePlayers, revealCount: 0, });
        setTimeout(() => {
            setTimeout(() => {
                this.playAudio(roundStartDrawingSFX, false, 0.6);
            }, 500)
            setTimeout(() => {
                this.playAudio(roundStartDrawingSFX, false, 0.6);
            }, 3000)
            this.doTitleAnim("Cards");
        }, 3000);
    }

    unreadyAll() {
        let players = [...this.state.players];
        players.forEach(x => {
            x.scrawlData.ready = false;
        })
        this.setState({ players });
    }

    setConnected(id, connected) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connected = connected;

        players[pos] = player;

        this.setState({ players });
    }

    updateConnectTimer(id, timer) {
        let players = [...this.state.players];
        let pos = players.map(function (e) { return e.id; }).indexOf(id);
        let player = { ...players[pos] };

        player.connectingTimer = timer;

        players[pos] = player;

        this.setState({ players });
    }

    updateRoundNums(totalRounds, roundNumber) {
        //if (totalRounds < roundNumber) {
        //    totalRounds = roundNumber;
        //}
        this.setState({ roundNumber, totalRounds, showRoundNumbers: true, });
    }

    getTickList() {
        let list = [];
        for (let i = 1; i <= this.state.totalRounds; i++) {
            let stopped = true;
            if (i <= this.state.roundNumber) stopped = false;
            list.push(
                <Lottie
                    options={tickCard}
                    width="5vh"
                    height="auto"
                    isClickToPauseDisabled={true}
                    isStopped={stopped}
                    style={{ marginLeft: "1vh", marginTop: "auto", marginBottom: "auto", }}
                />
            );
        }
        return list;
    }


    handleNextTurn(message) {
        this.unreadyAll();
        this.updateRoundNums(message.totalRounds, message.roundNumber);
        this.toggleTimer(false);
        this.setState({ isCards: false, });

        if (this.state.roundNumber % 2 === 0) {
            this.setState({ isDrawing: false, isWriting: true, roundTitle: "WRITE WHAT YOU SEE!" });
            setTimeout(() => {
                this.playAudio(roundStartWritingSFX, false, 1);
                setTimeout(() => {
                    this.toggleTimer(true);
                }, 1000);
            }, 500);
        } else {
            this.setState({ isDrawing: true, isWriting: false, roundTitle: "DRAW THE DESCRIPTION ON YOUR DEVICES!" });
            setTimeout(() => {
                this.playAudio(roundStartDrawingSFX, false, 1);
                setTimeout(() => {
                    this.toggleTimer(true);
                }, 1000);
            }, 500);
            
        }
        //this.setState({ isDrawing: true, isWriting: false, roundTitle: "DRAW or WRITE what's on your devices!" });
    }

    showDoodle(doodle) {
        if (this.state.doodlesOne.length > this.state.doodlesTwo.length) {
            let doodlesTwo = [...this.state.doodlesTwo];
            doodlesTwo.push(doodle);
            this.setState({ doodlesTwo, });
            setTimeout(() => {
                this.setState((prevState) => {
                    prevState.doodlesTwo.shift();
                    return { doodlesTwo: prevState.doodlesTwo }
                });
            }, 5000);
        } else {
            let doodlesOne = [...this.state.doodlesOne];
            doodlesOne.push(doodle);
            this.setState({ doodlesOne, });
            setTimeout(() => {
                this.setState((prevState) => {
                    prevState.doodlesOne.shift();
                    return { doodlesOne: prevState.doodlesOne }
                });
            }, 5000);
        }
    }

    doTitleAnim(context) {
        //let titles = [...this.state.titles];
        //let titleIndex = titles.map(x => x.context).indexOf(context);
        //let title = titles[titleIndex];

        //title.isPaused = false;
        //titles[titleIndex] = title;
        //this.setState({ titles });

        //this.titleRefs[context].play();
        switch (context) {
            case "Tutorial":
                this.setState({ playTitle: true, titleText: "HOW TO PLAY" });
                break;
            case "Cards":
                this.setState({ playTitle: true, titleText: "CHOOSE YOUR CARD" });
                break;
            case "WinnersAre":
                this.setState({ playTitle: true, titleText: "THE WINNER IS..." });
                break;
            case "BigReveal":
                this.setState({ playTitle: true, titleText: "TIME FOR THE BIG REVEAL" });
                break;
            default:
                break;
        }

        setTimeout(() => {
            this.doTitleComplete(context);
        }, 5500);
    }

    doTitleComplete(context) {
        //let titles = [...this.state.titles];
        //let titleIndex = titles.map(x => x.context).indexOf(context);
        //let title = titles[titleIndex];

        //title.isPaused = true;
        //titles[titleIndex] = title;
        //this.setState({ titles });

        //this.titleRefs[context].stop();

        this.setState({ playTitle: false, titleText: "" });
        
        switch (context) {
            case "Tutorial":
                this.setState({ doingTutorial: true, });
                setTimeout(() => {
                    this.gameBgAudio.volume(0.05);
                    this.state.room.send("show_tutorial", {});
                    this.setState({ showTutorial: true, });
                }, 500);
                break;
            case "Cards":
                this.state.room.send("start_game", {});
                break;
            case "WinnersAre":
                this.setState({ showWinners: true, doConfetti: true, });
                let stateWinners = [...this.state.winners];
                this.playAudio(winnerSFX, false, 0.6);
                for (let i = 0; i < stateWinners.length; i++) {
                    setTimeout(() => {
                        let player = { ...stateWinners[i] };
                        player.showSelf = true;
                        stateWinners[i] = player;
                        this.setState({ winners: stateWinners });
                    }, (i * 300));
                }
                setTimeout(() => {
                    this.showPlayAgain();
                }, 8000);
                break;
            case "BigReveal":
                this.gameBgAudio.pause();
                this.galleryBgAudio.play();
                break;
            default:
                this.toggleTimer(true);
                break;
        }
    }

    toggleTimer(show) {
        this.setState({ showTimer: show, });
    }

    startGame = (confirm = false, confirmContentWarning = false) => {
        let allConnected = true;
        for (const [key, value] of Object.entries(this.state.playerConnections)) {
            if (value === false) allConnected = false;
        }

        if (!confirmContentWarning && this.state.contentFilter === 1) {
            this.setState({ showContentWarning: true });
        }
        else if (!confirm && !allConnected) {
            this.setState({ showStartWarning: true });
        } else {
            this.setState({ gameCount: this.state.gameCount + 1, });
            this.playAudio(hoverOrClickSFX, false, 0.6);
            this.state.room.send("begin_game", { skipTutorial: this.state.tickedSkipTutorial, });
            this.setState({ showStartGame: false, showStartWarning: false, });
        }
    }

    closeStartWarning = () => {
        this.setState({ showStartWarning: false });
    }

    confirmNewGame = () => {
        this.playAgain(true);
    }

    confirmStartGame = () => {
        this.startGame(true);
    }

    closeContentWarning = () => {
        this.setState({ showContentWarning: false });
    }

    confirmContentWarning = () => {
        this.startGame(false, true);
        this.setState({ showContentWarning: false });
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartGame) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    getLeaderboardList(players) {
        let statePlayers = [...this.state.players];
        statePlayers.forEach(x => {
            x.scrawlData = players[x.id].scrawlData;
        });

        let leaderboardPlayers = [];
        statePlayers.forEach((x, index) => {
            let boardPlayer = {
                name: x.name,
                score: x.scrawlData.score,
                bgColor: PlayerColours[index],
                avatar: x.avatar,
            };
            leaderboardPlayers.push(boardPlayer);
        });
        leaderboardPlayers = leaderboardPlayers.sort((a, b) => {
            return b.score - a.score;
        });

        for (let i = 0; i < leaderboardPlayers.length; i++) {
            leaderboardPlayers[i].position = this.getPosition(leaderboardPlayers[i], i, leaderboardPlayers);
        }

        this.setState({ leaderboardPlayers, });
    }

    getPosition(player, index, leaderboardPlayers) {
        let position = 1;
        if (index > 0) {
            const playerAbove = leaderboardPlayers[index - 1];
            if (playerAbove != null) {
                if (player.score === playerAbove.score) return playerAbove.position;
                else return playerAbove.position + 1;
            }
        }
        return position;
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name !== "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }


    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at Scrawl");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    doReconnect = () => {


        if (this.state.reconnectTries < 5) {

            //const roomId = this.getQueryStringValue("roomId");
            //const sessionId = this.getQueryStringValue("sessionId");
            const token = this.getQueryStringValue("token");
            //console.log(roomId);
            if (this.state.connected === false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);

                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken});
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        const deviceInfo = JSON.parse(state.host.deviceDetailsJson);
                        if (deviceInfo.device.type === 'smarttv' || deviceInfo.ua.toLowerCase().includes('smarttv')) {
                            this.setState({ isSmartTV: true });
                        }

                        if (state.host.id !== room.sessionId) window.location = this.getRedirectURL();
                        Sentry.setUser({ id: state.host.uniqueId });
                        LoggingService.streamLog(state.uniqueId, `Host Reconnected to Scrawl, Reconnection Token: ${room.reconnectionToken}`);
                       
                        this.setState({ roomState: state, maxVoteCount: state.players.size, logStreamId: state.uniqueId });

                        if (state.scrawlData.gameState === GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.setState({ isGame: true, });
                            setTimeout(() => {
                                this.setState({ showStartGame: true, shrinkLogo: true, });
                            }, 1000);

                            this.startLocationChecks();

                            if (constants.isBot) {
                                this.setState({ tickedSkipTutorial: true });
                                setTimeout(() => {
                                    this.startGame(true, true);
                                }, 5000);
                            }
                        } else {
                            room.send("change_game", {});
                        }
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        
                        this.setState({ roomState: state, maxVoteCount: state.players.size,  });
                    });

                    room.state.availableAddons.onAdd((addon, key) => {
                        let availableAddons = [...this.state.availableAddons];
                        availableAddons.push(addon);
                        this.setState({ availableAddons });
                    });

                    room.state.scrawlData.listen("gameState", (currentValue, previousValue) => {
                        if (currentValue !== GameStates.Loading && currentValue !== GameStates.EndGame && this.state.showStartWarning) {
                            this.setState({ showStartWarning: false });
                        }
                        this.setState({ gameState: currentValue });
                    });

                    room.state.players.onAdd((player, key) => {
                        player.listen("connected", (currentValue, previousValue) => {
                            let playerConnections = { ...this.state.playerConnections };
                            playerConnections[key] = currentValue;
                            this.setState({ playerConnections });
                        });
                    });

                    room.onMessage("ping", (message) => {
                        console.log("ping", "received on", room.name, message);
                        this.startLocationCheck();
                    });

                    room.state.scrawlData.listen("contentFilter", (currentValue) => { this.setState({ contentFilter: currentValue }) });

                    //room.onMessage("all_connected", (message) => {
                    //    console.log("all_connected", "received on", room.name, message);
                    //    if (room.state.scrawlData.gameState == GameStates.Loading) {
                    //        this.setState({ isGame: true, });
                    //        for (const [key, value] of Object.entries(message.players)) {
                    //            this.addPlayer(value);
                    //        }
                    //        room.send("start_game", {});
                    //    }
                    //    if (Object.keys(message.players).length < this.state.playersRequired) {
                    //        room.send("change_game", {});
                    //    }
                    //});
                    room.onMessage("show_players", (message) => {
                        console.log("show_players", "received on", room.name, message);
                        for (const [key, value] of Object.entries(message.players)) {
                            this.addPlayer(value);
                        }
                        setTimeout(() => {
                            this.setState({ showPlayers: true, shrinkLogo: true, });
                        }, 2000);
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        this.setState({ showStartGame: false, })
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.scrawlData.gameState);
                        if ((this.state.roomState.scrawlData.gameState === GameStates.Loading || this.state.roomState.scrawlData.gameState === GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartGame: false, });
                            this.gameBgAudio.volume(0.2);
                            setTimeout(() => {
                                this.setState({ doingTutorial: false, });
                            }, 500);

                            room.send("start_playing", {});
                            setTimeout(() => {
                                setTimeout(() => {
                                    this.playAudio(roundStartDrawingSFX, false, 0.6);
                                }, 500)
                                setTimeout(() => {
                                    this.playAudio(roundStartDrawingSFX, false, 0.6);
                                }, 3000)
                                this.doTitleAnim("Cards");
                            }, 2000);
                        }
                    });
                    room.onMessage("player_leave", (message) => {
                        console.log("player_leave", "received on", room.name, message);
                        this.removePlayer(message.sessionId);
                    });
                    room.onMessage("player_joined", (message) => {
                        console.log("player_joined", "received on", room.name, message);
                        this.playAudio(joinedSFX, false, 0.6);
                        this.addPlayer(message.player);
                    });
                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });
                    room.onMessage("start_game", (message) => {
                        console.log("start_game", "received on", room.name, message);
                        this.setState({ isCards: true, });
                        this.toggleTimer(true);
                    });
                    room.onMessage("player_ready", (message) => {
                        console.log("player_ready", "received on", room.name, message);
                        this.setPlayerReady(message.id);
                    });
                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        // start potato animation in center here
                        this.unreadyAll();
                    });
                    room.onMessage("next_turn", (message) => {
                        console.log("next_turn", "received on", room.name, message);
                        this.handleNextTurn(message);
                    });
                    room.onMessage("round_over", (message) => {
                        console.log("round_over", "received on", room.name, message);
                        // end potato animation in center here
                        this.setState({ isDrawing: false, isWriting: false, });
                        this.unreadyAll();
                    });
                    room.onMessage("start_reveal", (message) => {
                        console.log("start_reveal", "received on", room.name, message);
                        this.startRevealing(message.player);
                    });
                    room.onMessage("reveal_next", (message) => {
                        console.log("reveal_next", "received on", room.name, message);
                        this.revealNext(message.story);
                    });
                    room.onMessage("all_revealed", (message) => {
                        console.log("all_revealed", "received on", room.name, message);
                        this.allRevealed();
                    });
                    room.onMessage("choose_best", (message) => {
                        console.log("choose_best", "received on", room.name, message);
                        this.chooseBest(message.item, message.player);
                    });
                    room.onMessage("no_best", (message) => {
                        console.log("no_best", "received on", room.name, message);
                        this.noBest();
                    });
                    room.onMessage("update_scores", (message) => {
                        console.log("update_scores", "received on", room.name, message);
                        this.updateScores(message.players);
                    });
                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);
                        this.doGameOver(message.winners, message.players);
                    });
                    room.onMessage("reset_round", (message) => {
                        console.log("reset_round", "received on", room.name, message);
                        this.resetRound();
                    });
                    room.onMessage("show_doodle", (message) => {
                        console.log("show_doodle", "received on", room.name, message);
                        this.showDoodle(message.doodle);
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.hidePlayAgain();
                        this.resetGame(message.players);
                    });
                    //room.onMessage("set_player_connected", (message) => {
                    //    console.log("set_player_connected", "received on", room.name, message);
                    //    this.setConnected(message.id, message.connected);
                    //});
                    room.onMessage("update_connect_timer", (message) => {
                        console.log("update_connect_timer", "received on", room.name, message);
                        this.updateConnectTimer(message.id, message.timer);
                    });
                    //room.onMessage("test_gif", (message) => {
                    //    console.log("test_gif", "received on", room.name, message);
                    //    this.createGIF(message.images);
                    //});
                    room.onMessage("update_timer", (message) => {
                        console.log("update_timer", "received on", room.name, message);
                        if (message.count <= 5) {
                            if (message.count <= 0) {
                            }
                            this.setState({ timerOptions: timerEnd });
                        } else {
                            this.setState({ timerOptions: timerTurning });
                        }
                        this.setState({ timer: message.count, });
                    });
                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.setState({ showStartGame: false, });
                        setTimeout(() => {
                            setTimeout(() => {
                                this.playAudio(roundStartDrawingSFX, false, 0.6);
                            }, 500)
                            this.doTitleAnim("Tutorial");
                        }, 3000);
                    });
                    room.onMessage("update_skip_vote", (message) => {
                        console.log("update_skip_vote", "received on", room.name, message);
                        this.setState({ skipTutorialCount: message.voteCount });
                    });

                    room.onError((code, message) => {
                        Sentry.captureMessage(`WS Received: onError`);
                        console.log(this.client.id, "couldn't join", room.name);

                        this.setState({ joinError: true });
                        LoggingService.streamLog(this.state.logStreamId, `Host OnError at Scrawl, code: ${code} Message: ${JSON.stringify(message)}`);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        LoggingService.streamLog(this.state.logStreamId, `Host Left Scrawl, Code: ${code}`);
                        // Sentry.captureMessage('Websocket disconnected: ' + (typeof constants.WSErrorCodes[code] !== 'undefined' ? constants.WSErrorCodes[code] : code), 'error');
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    const message = e.message ? e.message : "An error occured Hosting Scrawl.";
                    if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Host OnJoinError at Scrawl: ${JSON.stringify(e)}`);
                    Sentry.captureMessage(`doReconnect Error: ${message}`);
                    //LoggingService.logError(message, e);
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` })
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    changeContentFilter(value) {
        this.setState({ contentFilter: value });
        this.state.room.send("toggle_content_filter", { value: value });
        const userFriendlyValues = ["Family", "Adult"]
        console.log("changed content filter to: " + userFriendlyValues[value]);
    }

    render() {

        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={`${styles.gameContainer} ${this.state.contentFilter && styles.adults}`}>
                    {/*{*/}
                    {/*    this.state.doConfetti &&*/}
                    {/*    <Confetti*/}
                    {/*        width={window.innerWidth}*/}
                    {/*        height={window.innerHeight}*/}
                    {/*        numberOfPieces={this.state.isSmartTV ? 100 : 500}*/}
                    {/*        recycle={false}*/}
                    {/*        confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                    {/*        initialVelocityY={{ min: -10, max: -30, }}*/}
                    {/*        initialVelocityX={{ min: -10, max: 10, }}*/}
                    {/*        onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                    {/*    />*/}
                    {/*}*/}
                    {
                        !this.state.isGame ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} alt="logo" />
                            </div>
                            :
                            <React.Fragment>

                                {/*{*/}
                                {/*    this.state.titles.map((title, index) => {*/}
                                {/*        return <Lottie*/}
                                {/*            options={title.options}*/}
                                {/*            width="100%"*/}
                                {/*            height="100%"*/}
                                {/*            isClickToPauseDisabled={true}*/}
                                {/*            style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 20, touchAction: "none", userSelect: "none" }}*/}
                                {/*            eventListeners={[*/}
                                {/*                {*/}
                                {/*                    eventName: 'complete',*/}
                                {/*                    callback: () => this.doTitleComplete(title.context),*/}
                                {/*                },*/}
                                {/*            ]}*/}
                                {/*            isPaused={title.isPaused}*/}
                                {/*            ref={animation => {*/}
                                {/*                this.titleRefs[title.context] = animation;*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    })*/}
                                {/*}*/}
                                {
                                    this.state.showStartWarning && [GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) &&
                                    <ErrorModal
                                        title={"Are you ready to play?"}
                                        styles={"d-flex"}
                                        message={"It looks like all the players might not be connected to the game, are you sure you would like to start?"}
                                        callback={this.closeStartWarning}
                                        callbackText={"No"}
                                        callback2={this.state.showStartGame ? this.confirmStartGame : this.confirmNewGame}
                                        callbackText2={"Yes"}
                                    />
                                }
                                {
                                    this.state.showContentWarning && this.state.gameState === GameStates.Loading &&
                                    <ErrorModal
                                        title={"Content Warning 17+"}
                                        styles={"d-flex"}
                                        message={"You have selected to play with 17+ content. This content is not suitible for anyone under the age of 17 and you may also find some of the content offensive. Are you sure you wish to continue?"}
                                        callback={this.closeContentWarning}
                                        callbackText={"Back"}
                                        callback2={this.confirmContentWarning}
                                        callbackText2={"Continue"}
                                    />
                                }
                                <div className={`${styles.bannerContainer} ${this.state.playTitle && styles.play}`}>
                                    <img className={styles.bannerBg} src={bannerImg} alt="banner" />
                                    <div className={styles.bannerInfo}>
                                        <div className={styles.bannerTitle}>{this.state.titleText}</div>
                                    </div>
                                </div>
                                {
                                    this.state.doingTutorial ?
                                        <Tutorial show={this.state.showTutorial} skipCount={this.state.skipTutorialCount} playerCount={this.state.maxVoteCount} room={this.state.room} />
                                        :
                                        null
                                }
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                <div className={styles.doodleSection}>
                                    {
                                        this.state.doodlesOne.map((doodle) => {
                                            return <img src={doodle.url} className={styles.doodle} style={{ left: `${doodle.left}%`, top: `${doodle.top}%` }} alt="doodle" />
                                        })
                                    }
                                </div>
                                <div className={`${styles.doodleSection} ${styles.right}`}>
                                    {
                                        this.state.doodlesTwo.map((doodle) => {
                                            return <img src={doodle.url} className={styles.doodle} style={{ left: `${doodle.left}%`, top: `${doodle.top}%` }} alt="doodle" />
                                        })
                                    }
                                </div>
                                <div className={`${styles.counterSection} ${this.state.showRoundNumbers ? "" : styles.hidden}`}>
                                    {/*<div className={styles.counterText}>{`${this.state.roundNumber}/${this.state.totalRounds}`}</div>*/}
                                    <div className={styles.tickList}>
                                        {
                                            this.getTickList()
                                        }
                                    </div>
                                </div>
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} alt="mute" />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} alt="full-screen" />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} alt="help" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection} ${this.state.shrinkLogo ? styles.topLeft : ""}`}>
                                    <img src={logo} className={`${styles.logo}`} alt="logo" />
                                    <img src={logo17} className={`${styles.logo17} ${this.state.contentFilter === 1 && styles.show}`} alt="17+" />
                                </div>
                                <React.Fragment>
                                    <div id="middleColumn" className={styles.middleColumn}>
                                        <div className={`${styles.timerSection} ${this.state.showTimer ? "" : styles.hideTimer}`}>
                                            <div className={styles.timerBg}></div>
                                            <Lottie options={this.state.timerOptions} width="18vh" height="18vh" isClickToPauseDisabled={true} />
                                            <div className={styles.timer}>{this.state.timer}</div>
                                        </div>
                                        <div className={`${styles.roundSection} ${styles.draw} ${this.state.isDrawing || this.state.isWriting ? styles.show : ""}`}>
                                            <div className={styles.roundTitle}>Follow the instructions on your device!</div>
                                            <div className={styles.painters}>
                                                <div className={styles.painter}>
                                                    <Lottie options={potatoDraw} width="40vh" height="40vh" isClickToPauseDisabled={true} />
                                                </div>
                                                <div className={styles.painter}>
                                                    <Lottie options={potatoWrite} width="40vh" height="40vh" isClickToPauseDisabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className={`${styles.roundSection} ${styles.draw} ${this.state.isDrawing  ? styles.show : ""}`}>*/}
                                        {/*    <div className={styles.roundTitle}>DRAW!</div>*/}
                                        {/*    <div className={styles.painter}>*/}
                                        {/*        <Lottie options={potatoDraw} width="45vh" height="45vh" isClickToPauseDisabled={true} />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className={`${styles.roundSection} ${styles.write} ${this.state.isWriting ? styles.show : ""}`}>*/}
                                        {/*    <div className={styles.roundTitle}>WRITE!</div>*/}
                                        {/*    <div className={styles.painter}>*/}
                                        {/*        <Lottie options={potatoWrite} width="45vh" height="45vh" isClickToPauseDisabled={true} />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className={`${styles.roundSection} ${styles.cards} ${this.state.isCards ? styles.show : ""}`}>
                                            <div className={styles.roundTitle}>CHOOSE A CARD TO DRAW!</div>
                                            <div className={styles.cardSection}>
                                                <img src={cardsImg} className={styles.cards} alt="cards" />
                                                {/*<div className={`${styles.sampleCard} ${styles.one}`}>*/}
                                                {/*    <div className={`${styles.half} ${styles.top} ${styles.one}`}></div>*/}
                                                {/*    <div className={`${styles.half} ${styles.bottom} ${styles.one}`}></div>*/}
                                                {/*</div>*/}
                                                {/*<div className={`${styles.sampleCard} ${styles.two}`}>*/}
                                                {/*    <div className={`${styles.half} ${styles.top} ${styles.two}`}></div>*/}
                                                {/*    <div className={`${styles.half} ${styles.bottom} ${styles.two}`}></div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        {
                                            this.state.isRevealing ?
                                                <React.Fragment>
                                                    <div className={`${styles.awardSection} ${this.state.highlightBest ? styles.show : ""} ${this.state.dissapearBest ? styles.dissapear : ""}`}>
                                                        {
                                                            this.state.bestItem ?
                                                                <React.Fragment>
                                                                    <div className={styles.frameSection}>
                                                                        <div className={styles.frameItem}>
                                                                            {
                                                                                this.state.bestItem.isDrawing ?
                                                                                    <img className={styles.itemContent} src={this.state.bestItem.content} alt="drawing" />
                                                                                    :
                                                                                    <div className={styles.itemContent + " " + styles.text}>{this.state.bestItem.content}</div>
                                                                            }
                                                                        </div>
                                                                        <img src={frame} className={styles.frame} alt="frame" />
                                                                    </div>
                                                                    <div className={styles.playerSection}>
                                                                        <div className={`${styles.potato} ${this.state.popOutPotato ? styles.popout : ""}`}>
                                                                            <Lottie
                                                                                options={getAvatarById(this.state.bestItemPlayer.avatar).idleAnim}
                                                                                width="100%"
                                                                                height="100%"
                                                                                isClickToPauseDisabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className={styles.potName}>{this.state.bestItemPlayer.name}</div>
                                                                </React.Fragment>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className={styles.revealContainer}>
                                                        <div className={`${styles.ogSection} ${this.state.allRevealed ? styles.hide : ""}`}>
                                                            {/*<div className={styles.ogTitle}>Original card:</div>*/}
                                                            <div className={styles.ogCard}>{this.state.ogCard}</div>
                                                            <div className={styles.revealingName}>{this.state.playerRevealing.name}</div>
                                                            <div className={styles.revealingPotato}>
                                                                <Lottie
                                                                    options={getAvatarById(this.state.playerRevealing.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                            <div className={styles.revealList}>
                                                                {
                                                                    this.state.storyList.map(x => {
                                                                        return <div className={`${styles.revealItem} ${x.isEmpty ? styles.empty : ""} ${x.revealed ? styles.reveal : ""} ${x.hide ? styles.hide : ""}`}>
                                                                            {
                                                                                x.isEmpty ?
                                                                                    <div className={styles.itemContent + " " + styles.empty}>X</div>
                                                                                    :
                                                                                    x.isDrawing ?
                                                                                        <img className={styles.itemContent} src={x.content} alt="drawing" />
                                                                                        :
                                                                                        <div className={styles.itemContent + " " + styles.text}>{x.content}</div>
                                                                            }
                                                                            {/*<div className={styles.itemInfo}>*/}
                                                                            {/*    <div className={styles.name}>{x.byName}</div>*/}
                                                                            {/*</div>*/}
                                                                            {/*<div className={`${styles.mask} ${x.revealed ? styles.hide : ""}`}>*/}
                                                                            {/*    <img className={styles.maskLogo} src={logo} />*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className={`${styles.storyList} ${this.state.allRevealed ? styles.bigger : ""} ${this.state.highlightBest ? styles.hide : this.state.hideStory ? styles.hide : ""}`}>
                                                            <div className={`${styles.backdrop} ${this.state.choosingBest ? styles.hide : ""}`} />
                                                            {
                                                                this.state.storyList.map(x => {
                                                                    return <div className={`${styles.listItem} ${x.isEmpty ? styles.empty : ""}  ${x.hide ? styles.show : ""}`}>
                                                                        {
                                                                            x.isEmpty ?
                                                                                <div className={styles.itemContent + " " + styles.empty}>X</div>
                                                                                :
                                                                                x.isDrawing ?
                                                                                    <img className={styles.itemContent} src={x.content} alt="drawing"/>
                                                                                    :
                                                                                    <div className={styles.itemContent + " " + styles.text}>{x.content}</div>
                                                                        }
                                                                        {/*<div className={styles.itemInfo}>*/}
                                                                        {/*    <div className={styles.name}>{x.byName}</div>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className={`${styles.mask} ${x.revealed ? styles.hide : ""}`}>*/}
                                                                        {/*    <img className={styles.maskLogo} src={logo} />*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                this.state.showWinners ?
                                                    <div className={styles.winnersSection}>
                                                        {/*<div className={styles.winnersText}>{this.state.winnersText}</div>*/}
                                                        <div className={styles.winnersBox}>
                                                            {
                                                                this.state.players.map((x, index) => {
                                                                    return this.state.winners.map((y) => {
                                                                        if (x.id === y.id) {
                                                                            let isLeft = index < 4 ? true : false
                                                                            return <div className={`${styles.winner} ${y.showSelf ? styles.show : ""}`} style={{ backgroundColor: PlayerColours[index] }}>
                                                                                <div className={styles.winnerName}>{x.name}</div>
                                                                                <div className={`${styles.potato} ${isLeft ? styles.left : styles.right}`}>
                                                                                    <Lottie
                                                                                        options={getAvatarById(x.avatar).idleAnim}
                                                                                        width="100%"
                                                                                        height="100%"
                                                                                        isClickToPauseDisabled={true}
                                                                                    />
                                                                                </div>
                                                                                <div className={`${styles.number} ${isLeft ? styles.left : styles.right}`}>{x.scrawlData.score}</div>
                                                                            </div>
                                                                            //return <Player player={x} number={index + 1} left={index < 4 ? true : false} winner={true} colour={PlayerColours[index]} />
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null

                                        }
                                        <div className={`${styles.playAgainSection} ${this.state.showPlayAgain ? styles.show : ""}`}>
                                            <div className={styles.buttonsSection}>
                                                <div className={styles.button} onClick={() => this.playAgain(false)}>Play Again</div>
                                                <div className={`${styles.button} ${styles.smaller}`} onClick={this.differentGame}>Different Game</div>
                                            </div>
                                            <div className={styles.leaderboardSection}>
                                                {
                                                    this.state.leaderboardPlayers.map((x, index) => {
                                                        return <div className={styles.boardRow} style={{ backgroundColor: x.bgColor, }}>
                                                            <div className={styles.numberBox}>
                                                                <div className={styles.positionText}>{x.position}</div>
                                                            </div>
                                                            <div className={styles.nameBox}>{x.name}</div>
                                                            <div className={styles.scoreBox}>
                                                                <div className={styles.text}>{x.score}</div>
                                                                <div className={`${styles.text} ${styles.smaller}`}>PTS</div>
                                                            </div>
                                                            <div className={styles.potato}>
                                                                <Lottie
                                                                    options={getAvatarById(x.avatar).idleAnim}
                                                                    width="100%"
                                                                    height="100%"
                                                                    isClickToPauseDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className={`${styles.startGameSection} ${this.state.showStartGame ? styles.show : ""}`}>
                                            {this.state.availableAddons.includes("scrawl_adult") && <div className={styles.contentFilter}>
                                                <input type="range" min="0" max="1" value={this.state.contentFilter} className={styles.bar} id="filter" step="1" onChange={(e) => this.changeContentFilter(Number(e.target.value))} />
                                                <div className={styles.icons}>
                                                    <img src={familyIcon} alt="familyIcon" onClick={() => this.changeContentFilter(0)} />
                                                    <img src={adultsIcon} alt="adultsIcon" onClick={() => this.changeContentFilter(1)} />
                                                </div>
                                            </div>}
                                            <div className={styles.button} onClick={() => this.startGame()}>Start Game</div>
                                            <div className={`${styles.button} ${styles.smaller}`} onClick={this.differentGame}>Go To Lobby</div>
                                            <div className={styles.skipBox}>
                                                <input className={styles.checkbox} type="checkbox" id="checkbox" name="checkbox" onChange={this.toggleSkipTutorial} />
                                                <label for="checkbox">Skip Tutorial</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="playerRow" className={`${styles.playerRow} ${this.state.showPlayers ? "" : styles.hide}`}>
                                        {
                                            this.populatePlayers()
                                        }
                                    </div>
                                    {/*<div style={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 10000 }}>*/}
                                    {/*    {*/}
                                    {/*        this.state.titles.map((title) => {*/}
                                    {/*            return <button onClick={() => this.doTitleAnim(title.context)}>{title.context} Title</button>*/}
                                    {/*        })*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                </React.Fragment>
                            </React.Fragment>
                    }

                    {this.state.joinError &&
                        <ErrorModal
                        title={"Disconnected"}
                        styles={"d-flex"}
                        message={"There is a problem connecting to the game. Please refresh to try again, if the problem persists it may be a firewall blocking your attempts."}
                        callback={this.reloadPage}
                        callbackText={"Try again"}
                        />
                    }
                </div>
                <input type="hidden" id="game-count" name="game-count" value={this.state.gameCount} />
            </div>
        );
    }
}